import React from 'react'
import { useLocation } from 'react-router-dom'
import UserList from 'src/components/UserList'

const User = () => {
  const location = useLocation()

  return (
    <>
      <UserList 
        path={location.pathname}
      />
    </>
  )
}

export default User