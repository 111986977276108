import Dashboard from './views/dashboard/Dashboard'
import User from './views/user/User'
import UserForm from './views/user/UserForm'
import UserDetail from './views/user/UserDetail'

import Ads from './views/ads/Ads'
import AdsForm from './views/ads/AdsForm'
import AdsDetail from './views/ads/AdsDetail'

import Monitoring from './views/monitoring/Monitoring'

import Notif from './views/notif/Notif'

const routes = [
  { path: '/', exact: true, name: 'Home' },
  { path: '/dashboard', exact: true, name: 'Dashboard', component: Dashboard },
  { path: '/admin', exact: true, name: 'Admin', component: User },
  { path: '/admin/add', name: 'Add', component: UserForm },
  { path: '/admin/edit/:user_id', name: 'Edit', component: UserForm },
  { path: '/admin/detail/:user_id', name: 'Detail', component: UserDetail },
  { path: '/partner', exact: true, name: 'Partner', component: User },
  { path: '/partner/add', name: 'Add', component: UserForm },
  { path: '/partner/edit/:user_id', name: 'Edit', component: UserForm },
  { path: '/partner/detail/:user_id', name: 'Detail', component: UserDetail },
  { path: '/ads', exact: true, name: 'Ads', component: Ads },
  { path: '/ads/add', name: 'Add', component: AdsForm },
  { path: '/ads/detail/:ads_id', name: 'Detail', component: AdsDetail },
  { path: '/ads/edit/:ads_id', name: 'Edit', component: AdsForm },
  { path: '/ads-monitoring', exact: true, name: 'Ads Monitoring', component: Monitoring },
  { path: '/ads-monitoring/detail/:ads_id', name: 'Detail', component: AdsDetail },
  { path: '/ads-monitoring/add', name: 'Add', component: AdsForm },
  { path: '/notification', exact: true, name: 'Notification', component: Notif },
]

export default routes
