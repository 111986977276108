import React, { useEffect, useState } from 'react'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CFormSelect,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilNotes,
  cilPlus
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'

const MySwal = withReactContent(Swal)
const pageRow = 10

const Ads = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })
  const [status, setStatus] = useState('all')

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    };

    if(status!=='all'){
      data_post['filter'] = [
        {
          field: "status",
          operator: "=",
          value: status
        }
      ]
    }

    http.get(`ads`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [status])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Ads Content</h4>
              <div className="small text-medium-emphasis">List</div>
            </CCol>
            
            <CCol md={4} className="d-none d-md-block">
              <div className='d-flex justify-content-end'>
                <CFormSelect aria-label="Default select example" onChange={(data)=>setStatus(data.target.value)}>
                  <option value={'all'}>All Status</option>
                  <option value={0}>Pending</option>
                  <option value={1}>Approved</option>
                  <option value={2}>Rejected</option>
                </CFormSelect>
                {
                  user.type!=="superadmin" &&
                  <CButton 
                    style={{width:300}}
                    color="info" 
                    className="float-end text-white ms-4" 
                    onClick={()=>history.push({
                      pathname: `/ads/add`,
                      state: { type: 'add' }
                    })}
                  >
                    <CIcon icon={cilPlus} /> Create Ads
                  </CButton>
                }
              </div>
            </CCol>
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Ads Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Start Date</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">End Date</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Play</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">From</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="7" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                  </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        var statusColor = "secondary"
                        if(item.status===0){
                          statusColor = "warning"
                        }else if(item.status===1){
                          statusColor = "success"
                        }else if(item.status===2){
                          statusColor = "danger"
                        }

                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.ads_name}</CTableDataCell>
                            <CTableDataCell className="text-center">{moment(item.start_date).format('DD MMM YYYY')}</CTableDataCell>
                            <CTableDataCell className="text-center">{moment(item.end_date).format('DD MMM YYYY')}</CTableDataCell>
                            <CTableDataCell className="text-center">{item.display}</CTableDataCell>
                            <CTableDataCell className="text-center">{item.user_from.fullname}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CBadge color={statusColor}>{item.status_var.label}</CBadge>
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="success" 
                                className="text-white" 
                                size='sm'
                                onClick={()=>history.push({
                                  pathname: '/ads/detail/'+item.ads_id
                                })}
                              >
                                <CIcon icon={cilNotes} />&nbsp; Detail
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Ads