import React, { useEffect, useState } from 'react'

import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilCheckCircle,
  cilPencil, 
  cilXCircle
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor, DangerColor, SuccessColor } from 'src/components/Color'
import { useHistory, useParams } from 'react-router-dom'
import Loader from 'src/components/Loader'
import moment from 'moment'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'
import { useDispatch } from 'react-redux'

const MySwal = withReactContent(Swal)

const AdsDetail = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const dispatch = useDispatch()
  const { ads_id } = useParams()
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState(null)
  
  const getData = () => {
    setLoading(true)

    http.get(`ads/${ads_id}`)
    .then((res) => {
      console.log('res', res.data)
      if(res.data.status){
        setData(res.data.data)
      }else{
        history.goBack()
        MySwal.fire({
          icon: 'error',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }

      setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      history.goBack()
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  const colorStatus = (status) => {
    var statusColor = "secondary"
    if(status===0){
      statusColor = "warning"
    }else if(status===1){
      statusColor = "success"
    }else if(status===2){
      statusColor = "danger"
    }

    return statusColor
  }

  if(!data && loading){
    return(
      <Loader visible={loading}/>
    )
  }
  
  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Ads Detail
              </h4>
              <div className="small text-medium-emphasis">Ads Data</div>
            </CCol>
            {
              user.type!=='superadmin' && (data.status===0 || (user.type==='admin' && data.status!==2)) &&
              <CCol sm={7} className="d-none d-md-block">
                <CButton 
                  color="info" 
                  className="float-end text-white" 
                  onClick={()=>history.push({
                    pathname: '/ads/edit/'+ads_id,
                    state: { type: 'edit', data: data, category_id: data.category_id }
                  })}
                >
                  <CIcon icon={cilPencil} />&nbsp; Edit Ads
                </CButton>
              </CCol>
            }
          </CRow>
          <CRow className='mt-2'>
            <CCol sm={5}>
              <dl className="row mb-0">
                <dt className="col-sm-4">Ads Name</dt>
                <dd className="col-sm-8">{data.ads_name}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">From</dt>
                <dd className="col-sm-8">{data.user_from.fullname}</dd>
              </dl>
              {
                (data.ads_description!==null && data.ads_description!=='') &&
                <dl className="row mb-0">
                  <dt className="col-sm-4">Desc</dt>
                  <dd className="col-sm-8">{data.ads_description}</dd>
                </dl>
              }
              <dl className="row mb-0">
                <dt className="col-sm-4">Start Date</dt>
                <dd className="col-sm-8">{moment(data.start_date).format('DD MMMM YYYY')}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">End Date</dt>
                <dd className="col-sm-8">{moment(data.end_date).format('DD MMMM YYYY')}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Play</dt>
                <dd className="col-sm-8">{data.display}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Status</dt>
                <dd className="col-sm-8">
                  <CBadge color={colorStatus(data.status)}>{data?.status_var?.label}</CBadge>
                </dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Created By</dt>
                <dd className="col-sm-8">{data.user_created.fullname}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Created Date</dt>
                <dd className="col-sm-8">{moment(data.created_at).format('DD MMMM YYYY HH:mm')}</dd>
              </dl>
              
              {
                data.user_updated && 
                <dl className="row mb-0">
                  <dt className="col-sm-4">Updated By</dt>
                  <dd className="col-sm-8">{data.user_updated.fullname}</dd>
                </dl>
              }
              <dl className="row mb-0">
                <dt className="col-sm-4">Update Date</dt>
                <dd className="col-sm-8">{moment(data.updated_at).format('DD MMMM YYYY HH:mm')}</dd>
              </dl>

              {
                user.type==='admin' && data.status===0 &&
                <div className='d-flex mt-3'>
                  <CButton 
                    color="success" 
                    className="float-end text-white me-2"
                    onClick={()=>{
                      MySwal.fire({
                        icon: 'question',
                        title: 'Confirmation',
                        html: `Are you sure want to <strong>VERIFY</strong> this ads?`,
                        confirmButtonColor: SuccessColor(),
                        confirmButtonText: 'Verify',
                        showCancelButton: true,
                        cancelButtonColor: '#ccc',
                        canceButtonText: 'Cancel'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          changeStatus('verify')
                        }
                      })
                    }}
                  >
                    <CIcon icon={cilCheckCircle} />&nbsp; Verify
                  </CButton>
                  <CButton 
                    color="danger" 
                    className="float-end text-white"
                    onClick={()=>{
                      MySwal.fire({
                        icon: 'question',
                        title: 'Confirmation',
                        html: `Are you sure want to <strong>REJECT</strong> this ads?`,
                        confirmButtonColor: DangerColor(),
                        confirmButtonText: 'Reject',
                        showCancelButton: true,
                        cancelButtonColor: '#ccc',
                        canceButtonText: 'Cancel'
                      }).then((result) => {
                        if (result.isConfirmed) {
                          changeStatus('reject')
                        }
                      })
                    }}
                  >
                    <CIcon icon={cilXCircle} />&nbsp; Reject
                  </CButton>
                </div>
              }
            </CCol>
            <CCol sm={7}>
              <video
                className="VideoInput_video"
                width={"100%"}
                height={"auto"}
                controls
                src={data.url}
              >
                <track kind="captions"/>
              </video>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )

  function changeStatus(status) {
    setLoading(true)

    const data_post = {
      ads_id: ads_id
    }

    http.put(`ads/status/${status}`, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        dispatch({ type: 'set', trigger: moment().valueOf() });
        getData()
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default AdsDetail
