import React, { useEffect, useRef, useState } from "react";
import PropTypes from 'prop-types';
import axios from "axios";
import {
  CButton,
  CSpinner
} from '@coreui/react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import CIcon from "@coreui/icons-react";
import { cilCloudUpload } from "@coreui/icons";
import { BrandColor } from "./Color";

import videoPlaceholder from '../assets/images/video_player_placeholder.gif';
const MySwal = withReactContent(Swal)

const VideoInput = (props) => {
  const { onChange, disabled, value } = props;
  const [loading, setLoading] = useState(false);

  const inputRef = useRef();

  const [source, setSource] = useState(value);

  useEffect(()=>{
    setSource(value)
  }, [value])

  const handleFileChange = (event) => {
    setLoading(true);
    
    const formData = new FormData();
    formData.append("file", event.target.files[0]);
    formData.append("email", process.env.REACT_APP_CDN_EMAIL);
    formData.append("password", process.env.REACT_APP_CDN_PASSWORD);

    axios.post(process.env.REACT_APP_CDN_URL, formData)
    .then((response) => {
      console.log('response', response)
      if (response.data.status) {
        // berhasil
        setSource(response.data.data.url);
        onChange(response.data.data);
      } else {
        // gagal
        MySwal.fire({
          icon: 'error',
          text: response.data.error,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch((error) => {
      MySwal.fire({
        icon: 'error',
        text: 'Terjadi kesalahan, silahkan coba lagi!',
        confirmButtonColor: BrandColor(),
        confirmButtonText: 'Close'
      })
      setLoading(false);
    });
  };

  const handleChoose = (event) => {
    inputRef.current.click();
  };

  return (
    <div className="VideoInput">
      <input
        ref={inputRef}
        className="VideoInput_input"
        type="file"
        onChange={handleFileChange}
        accept=".mov,.mp4"
      />
      
      <div style={{position:'relative'}}>
        {
          loading &&
          <div className="Videoinput_loader">
            <div className="Videoinput_loader_icon">
              <CSpinner color="white" size="sm" /> &nbsp; Mengupload File...
            </div>
          </div>
        }
      
        {source ? (
          <video
            className="VideoInput_video"
            width={"100%"}
            height={"auto"}
            controls
            src={source}
          >
            <track kind="captions"/>
          </video>
        ):(
          <img
            width={"100%"}
            alt=''
            className='img-fluid'
            src={videoPlaceholder}
          />
        )}
      </div>
      <div className="VideoInput_footer">
        <CButton
          color='info'
          onClick={handleChoose}
          disabled={loading||disabled}
          className="text-white"
        >
          <CIcon icon={cilCloudUpload} />&nbsp; {!source?'Upload':'Ganti'} Video
        </CButton>
      </div>
    </div>
  );
}

VideoInput.propTypes = {
	onChange: PropTypes.func,
  disabled: PropTypes.bool,
  value: PropTypes.any
}
VideoInput.defaultProps = {
	onChange: null,
  disabled: false,
  value: null
};

export default VideoInput