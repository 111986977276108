import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import {
  CContainer,
  CHeader,
  CHeaderBrand,
  CHeaderDivider,
  CHeaderNav,
  CHeaderToggler,
  CDropdownToggle,
  CDropdownMenu,
  CDropdownHeader,
  CDropdown,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilBell, cilMenu } from '@coreui/icons'
import styled from 'styled-components';

import { AppBreadcrumb } from './index'
import { AppHeaderDropdown } from './header/index'
import { logo } from 'src/assets/brand/logo'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'
import http from 'src/service/Request'
import NotificationList from './NotificationList'

const NotifWrapper = styled.div`
  background-color: #2eb75c;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  position: absolute;
  top: -8px;
  right: -8px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

const NotifText = styled.div`
  color: #fff;
  font-size: .8rem;
`;

const NotifAll = styled.div`
  color: #2eb75c;
  padding: 10px 15px 5px 15px;
  text-align: center;
  cursor: pointer;
  font-size: .8rem;
  font-weight: bold;
`

const NotifNotFound = styled.div`
  color: #68726f;
  padding: 10px 15px 5px 15px;
  text-align: center;
  cursor: pointer;
  font-size: .8rem;
  font-weight: bold;
  border-bottom: 1px solid #d7ecdd;
  padding-bottom: 10px;
`

const AppHeader = () => {
  const dispatch = useDispatch()
  const { trigger, sidebarShow } = useSelector(state=>state)
  const location = useLocation()
  const history = useHistory()
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const [notif, setNotif] = useState({
    count: 0,
    data: []
  })

  const loadCounter = () => {
    http.get(`notification/pending/count`)
    .then((res) => {
      console.log('notif', res)
      if(res.data.status){
        setNotif({
          count: res.data.count,
          data: res.data.data
        })
      }
    })
  }

  useEffect(()=>{
    if(user.type!=='superadmin'){
      loadCounter()
    }
  }, [location, trigger])

  return (
    <CHeader position="sticky" className="mb-0">
      <CContainer fluid>
        <CHeaderToggler
          className="ps-1"
          onClick={() => dispatch({ type: 'set', sidebarShow: !sidebarShow })}
        >
          <CIcon icon={cilMenu} size="lg" />
        </CHeaderToggler>
        <CHeaderBrand className="mx-auto d-md-none" to="/">
          <CIcon icon={logo} height={48} alt="Logo" />
        </CHeaderBrand>
        <CHeaderNav className="d-none d-md-flex me-auto">

        </CHeaderNav>
        <CHeaderNav>
          {
            user.type!=='superadmin' &&
            <CDropdown variant="nav-item">
              <CDropdownToggle placement="notification" className="py-0" caret={false}>
                <div style={{position:'relative', marginTop: 10}}>
                  <CIcon icon={cilBell} size="xl" />
                  {
                    notif.count > 0 &&
                    <NotifWrapper>
                      <NotifText>{notif.count}</NotifText>
                    </NotifWrapper>
                  }
                </div>
              </CDropdownToggle>
              <CDropdownMenu className="pt-0 mt-2" placement="notification">
                <CDropdownHeader className="bg-light fw-semibold py-2">Notifications</CDropdownHeader>
                <div>
                  {
                    notif.data.length === 0 &&
                    <NotifNotFound>No notifications yet</NotifNotFound>
                  }
                  {
                    notif.data.map((item, index) => {
                      return(
                        <NotificationList key={index} item={item}/>
                      )
                    })
                  }
                </div>
                <NotifAll
                  onClick={()=>history.push({
                    pathname: `/notification`
                  })}
                >
                  See All
                </NotifAll>
              </CDropdownMenu>
            </CDropdown>
          }

        </CHeaderNav>
        <CHeaderNav className="ms-3">
          <AppHeaderDropdown />
        </CHeaderNav>
      </CContainer>
      <CHeaderDivider />
      <CContainer fluid>
        <AppBreadcrumb />
      </CContainer>
    </CHeader>
  )
}

export default AppHeader
