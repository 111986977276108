import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilNotes,
  cilPlus
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'
import { useHistory } from 'react-router-dom'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'

const MySwal = withReactContent(Swal)
const pageRow = 10

const UserList = (props) => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const { path, onSelect, value } = props
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  });

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    };

    http.get(`users${path}`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setData((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
          }));
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  };

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [])

  const checkSelect = (user_id) => {
    if(value?.user_id === user_id){
      return true
    }else{
      return false
    }
  }

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            {
              !onSelect &&
              <CCol sm={5}>
                <h4 id="traffic" className="card-title mb-0">
                {path==='/admin'?'Admin':'Partner'}
                </h4>
                <div className="small text-medium-emphasis">User Data</div>
              </CCol>
            }

            {
              !onSelect &&
              <CCol sm={7} className="d-none d-md-block">
                <CButton 
                  color="info" 
                  className="float-end text-white" 
                  onClick={()=>history.push({
                    pathname: `${path}/add`,
                    state: { type: 'add', path: path }
                  })}
                >
                  <CIcon icon={cilPlus} /> Create {path==='/admin'?'Admin':'Partner'}
                </CButton>
              </CCol>
            }
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Name</CTableHeaderCell>
                  <CTableHeaderCell>Email</CTableHeaderCell>
                  {
                    path==='/partner' && user.type==='superadmin' &&
                    <CTableHeaderCell>Admin</CTableHeaderCell>
                  }
                  <CTableHeaderCell className="text-center">Status</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                data.data.length ===0 && !loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="4" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      data.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.fullname}</CTableDataCell>
                            <CTableDataCell>{item.email}</CTableDataCell>
                            {
                              path==='/partner' && user.type==='superadmin' &&
                              <CTableDataCell>{item.parent.fullname}</CTableDataCell>
                            }
                            <CTableDataCell className="text-center">
                              {
                                item.status==="1" ? (
                                  <CBadge color="success">Active</CBadge>
                                ) : (
                                  <CBadge color="danger">Inactive</CBadge>
                                )
                              }
                            </CTableDataCell>
                            <CTableDataCell className="text-center">
                              {
                                onSelect?(
                                  <CButton 
                                    color={checkSelect(item.user_id)?'secondary':'success'} 
                                    className="text-white" 
                                    size='sm'
                                    disabled={checkSelect(item.user_id)}
                                    onClick={()=>onSelect(item)}
                                  >
                                    {checkSelect(item.user_id)?'Selected':'Select'}
                                  </CButton>
                                ):(
                                  <CButton 
                                    color="success" 
                                    className="text-white" 
                                    size='sm'
                                    onClick={()=>history.push({
                                      pathname: path+'/detail/'+item.user_id,
                                      state: { path: path }
                                    })}
                                  >
                                    <CIcon icon={cilNotes} />&nbsp; Detail
                                  </CButton>
                                )
                              }
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              data.data.length > 0 &&
              <Pagination
                page={data.page}
                totalPage={data.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

UserList.propTypes = {
  path: PropTypes.string,
  onSelect: PropTypes.func,
}

export default UserList