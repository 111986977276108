import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation } from 'react-router-dom'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'
import AdminSelect from 'src/components/AdminSelect'
import 'rc-time-picker/assets/index.css';
import moment from 'moment';
import TimePicker from 'rc-time-picker';

const MySwal = withReactContent(Swal)

const UserForm = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const history = useHistory()
  const { state } = useLocation()
  const { type, data, path } = state
	const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [active, setActive] = useState(1)
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [admin, setAdmin] = useState(null)
  const [partner, setPartner] = useState({
    phone: '',
    address: '',
    open: moment(),
    close: moment()
  })

  useEffect(()=>{
    if(type==='edit'){
      setName(data.fullname)
      setActive(parseInt(data.status))
      setEmail(data.email)
      setAdmin(data.parent)

      setPartner({
        phone: data.phone,
        address: data.address,
        open: data.open?moment('2022-12-12 '+ data.open):moment(),
        close: data.close?moment('2022-12-12 '+ data.close):moment()
      })
    }
  }, [])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} {path==='/admin'?'Admin':'Partner'}
              </h4>
              <div className="small text-medium-emphasis">User Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            {
              user.type==='superadmin' && path==='/partner' &&
              <CCol md={6}>
                <CForm>
                  <div className="mb-3">
                    <CFormLabel>Admin</CFormLabel>
                    <AdminSelect
                      type="admin"
                      value={admin}
                      onSelect={(data)=>setAdmin(data)}
                    />
                  </div>
                </CForm>
              </CCol>
            }
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Full Name</CFormLabel>
                  <CFormInput
                    type="input"
                    placeholder="Full Name"
                    value={name}
                    onChange={(txt)=>setName(txt.target.value)}
                  />
                </div>
              </CForm>
            </CCol>
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Email</CFormLabel>
                  <CFormInput
                    type="input"
                    placeholder="Email"
                    value={email}
                    onChange={(txt)=>setEmail(txt.target.value)}
                  />
                </div>
              </CForm>
            </CCol>
            {
              type==='add' &&
              <CCol md={6}>
                <CForm>
                  <div className="mb-3">
                    <CFormLabel>Password</CFormLabel>
                    <CFormInput
                      type="input"
                      placeholder="Password"
                      value={password}
                      onChange={(txt)=>setPassword(txt.target.value)}
                    />
                  </div>
                </CForm>
              </CCol>
            }
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Status</CFormLabel>
                  <div className="d-flex flex-row mt-2">
                    <CFormCheck
                      type="radio"
                      label="Active"
                      checked={active===1}
                      onClick={()=>setActive(1)}
                    />
                    <CFormCheck
                      type="radio"
                      label="Inactive"
                      className="ms-3"
                      checked={active===0}
                      onClick={()=>setActive(0)}
                    />
                  </div>
                </div>
              </CForm>
            </CCol>
          </CRow>
          <CRow>
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Phone</CFormLabel>
                  <CFormInput
                    type="input"
                    placeholder="Phone"
                    value={partner.phone}
                    onChange={(txt)=>setPartner(state=>({...state, phone: txt.target.value}))}
                  />
                </div>
              </CForm>
              {
                path==='/partner' &&
                <CRow>
                  <CCol md={6}>
                    <CForm>
                      <div className="mb-3">
                        <CFormLabel>Open At</CFormLabel>
                        <TimePicker 
                          className="form-control"
                          showSecond={false} 
                          allowEmpty={false} 
                          value={partner.open}
                          onChange={(txt)=>setPartner(state=>({...state, open: txt}))}
                        />
                      </div>
                    </CForm>
                  </CCol>
                  <CCol md={6}>
                    <CForm>
                      <div className="mb-3">
                        <CFormLabel>Close At</CFormLabel>
                        <TimePicker 
                          className="form-control"
                          showSecond={false} 
                          allowEmpty={false} 
                          value={partner.close}
                          onChange={(txt)=>setPartner(state=>({...state, close: txt}))}
                        />
                      </div>
                    </CForm>
                  </CCol>
                </CRow>
              }
            </CCol>
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Address</CFormLabel>
                  <CFormTextarea
                    type="input"
                    placeholder="Address"
                    value={partner.address}
                    onChange={(txt)=>setPartner(state=>({...state, address: txt.target.value}))}
                    rows={5}
                  />
                </div>
              </CForm>
            </CCol>
          </CRow>
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    const data_post = {
      fullname: name,
      status: active,
      email: email,
      password: password,
      type: path.replace('/',''),
      phone: partner.phone,
      address: partner.address
    }
    if(path==='/partner'){
      if(user.type==='superadmin'){
        data_post['parent_id'] = admin.user_id
      }else{
        data_post['parent_id'] = user.user_id
      }

      data_post['open'] = moment(partner.open).format('HH:mm')
      data_post['close'] = moment(partner.close).format('HH:mm')
    }
    http.post('users/create', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      user_id: data.user_id,
      fullname: name,
      status: active.toString(),
      email: email,
      password: password,
      type: path.replace('/',''),
      phone: partner.phone,
      address: partner.address
    }
    if(path==='/partner'){
      if(user.type==='superadmin'){
        data_post['parent_id'] = admin.user_id
      }else{
        data_post['parent_id'] = user.user_id
      }

      data_post['open'] = moment(partner.open).format('HH:mm')
      data_post['close'] = moment(partner.close).format('HH:mm')
    }
    http.put('users', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default UserForm