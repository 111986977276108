import React, { useEffect, useState } from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

import { CBadge } from '@coreui/react'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'
import http from 'src/service/Request'
import { useSelector } from 'react-redux'

export const AppSidebarNav = ({ items }) => {
  const { trigger } = useSelector(state=>state)
  const location = useLocation()
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const [counter, setCounter] = useState(0)

  const loadCounter = () => {
    http.get(`ads/pending/count`)
    .then((res) => {
      if(res.data.status){
        setCounter(res.data.data)
      }
    })
  }

  useEffect(()=>{
    if(user.type==='admin'){
      loadCounter()
    }
  }, [location, trigger, user])

  const navLink = (name, icon, badge) => {
    
    return (
      <>
        {icon && icon}
        {name && name}
        {badge && name==='Ads' && counter > 0 && (
          <CBadge color="warning" className="ms-auto">
            {counter}
          </CBadge>  
        )}
        {badge && (
          <CBadge color={badge.color} className="ms-auto">
            {badge.text}
          </CBadge>
        )}
      </>
    )
  }

  const navItem = (item, index) => {
    const { component, name, badge, icon, ...rest } = item
    const Component = component
    return (
      <Component
        {...(rest.to &&
          !rest.items && {
            component: NavLink,
            activeClassName: 'active',
          })}
        key={index}
        {...rest}
      >
        {navLink(name, icon, badge)}
      </Component>
    )
  }
  const navGroup = (item, index) => {
    const { component, name, icon, to, ...rest } = item
    const Component = component
    return (
      <Component
        idx={String(index)}
        key={index}
        toggler={navLink(name, icon)}
        visible={location.pathname.startsWith(to)}
        {...rest}
      >
        {item.items?.map((item, index) =>
          item.items ? navGroup(item, index) : navItem(item, index),
        )}
      </Component>
    )
  }

  return (
    <React.Fragment>
      {items &&
        items.map((item, index) => (item.items ? navGroup(item, index) : navItem(item, index)))}
    </React.Fragment>
  )
}

AppSidebarNav.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any),
}
