import React from 'react'
import CIcon from '@coreui/icons-react'
import {
  cilBarChart,
  cilFeaturedPlaylist,
  cilGroup,
  cilSpeedometer,
  cilUser
} from '@coreui/icons'
import { CNavItem } from '@coreui/react'

export const nav_su = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Admin',
    to: '/admin',
    icon: <CIcon icon={cilUser} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Partner',
    to: '/partner',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Ads',
    to: '/ads',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />
  }
]

export const nav_admin = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Partner',
    to: '/partner',
    icon: <CIcon icon={cilGroup} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Ads',
    to: '/ads',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
    badge: true,
  },
  {
    component: CNavItem,
    name: 'Ads Monitoring',
    to: '/ads-monitoring',
    icon: <CIcon icon={cilBarChart} customClassName="nav-icon" />
  }
]

export const nav_partner = [
  {
    component: CNavItem,
    name: 'Dashboard',
    to: '/dashboard',
    icon: <CIcon icon={cilSpeedometer} customClassName="nav-icon" />
  },
  {
    component: CNavItem,
    name: 'Ads',
    to: '/ads',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />,
    badge: true,
  },
  {
    component: CNavItem,
    name: 'Ads Monitoring',
    to: '/ads-monitoring',
    icon: <CIcon icon={cilFeaturedPlaylist} customClassName="nav-icon" />
  }
]
