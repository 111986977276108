import React, { useState } from 'react'
import {
  CButton,
  CCard,
  CCardBody,
  CCardGroup,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CImage,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import { cilLockLocked, cilUser } from '@coreui/icons'
import logoNegative from './../../../assets/brand/logo-light.png'
import { Redirect, useHistory } from 'react-router-dom'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import http from 'src/service/Request'
import Cookies from "js-cookie";
import { encrypt } from 'src/service/Cryptograph'
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'

const MySwal = withReactContent(Swal)

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();

  const token = Cookies.get("token");

  if(token){
    return <Redirect to={'/'}/>
  }

  return (
    <div className="bg-light min-vh-100 d-flex flex-row align-items-center">
      <Loader visible={loading}/>

      <CContainer>
        <CRow className="justify-content-center">
          <CCol md={7}>
            <CCardGroup>
              <CCard className="p-4">
                <CCardBody>
                  <CForm>
                    <h1>Login</h1>
                    {
                      error!==''?(
                        <p className='text-danger'>{error}</p>
                      ):(
                        <p className="text-medium-emphasis">Sign In to your account</p>
                      )
                    }
                    <CInputGroup className="mb-3">
                      <CInputGroupText>
                        <CIcon icon={cilUser} />
                      </CInputGroupText>
                      <CFormInput 
                        placeholder="Email" 
                        autoComplete="email"
                        value={email}
                        onChange={(txt)=>setEmail(txt.target.value)}
                      />
                    </CInputGroup>
                    <CInputGroup className="mb-4">
                      <CInputGroupText>
                        <CIcon icon={cilLockLocked} />
                      </CInputGroupText>
                      <CFormInput
                        type="password"
                        placeholder="Password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(txt)=>setPassword(txt.target.value)}
                      />
                    </CInputGroup>
                    <CRow>
                      <CCol xs={6}>
                        <CButton color="info" className="px-4 text-white" onClick={()=>login()}>
                          Login
                        </CButton>
                      </CCol>
                      {/* <CCol xs={6} className="text-right">
                        <CButton color="link" className="px-0">
                          Forgot password?
                        </CButton>
                      </CCol> */}
                    </CRow>
                  </CForm>
                </CCardBody>
              </CCard>
              <CCard className="text-white bg-primary py-5" style={{ width: '44%' }}>
                <CCardBody className="d-flex justify-content-center align-items-center">
                  <div className='text-center'>
                    <CImage className="sidebar-brand-full" src={logoNegative} height={30} />
                    <p className='mt-4'>
                      An integrated digital engagement platfrom for your business needs and improvements.
                    </p>
                  </div>
                </CCardBody>
              </CCard>
            </CCardGroup>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  )

  async function login() {
    setLoading(true);
    const data_post ={
      email: email,
      password: password
    }
    
    http
		.post('auth/login', data_post)
		.then(response => {
      console.log('resp login', response);
      if(response.data.status){
        Cookies.set('token', encrypt(response.data.Authorization), { expires: 1 });
        Cookies.set('user', encrypt(JSON.stringify(response.data.data)), { expires: 1 });
        history.replace('/dashboard');
      }else{
        setError(response.data.message)
      }
      setLoading(false);
		})
		.catch(error => {
			console.log('error', error.response);
			if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
			} else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
			} else {
				MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
			}
			setLoading(false);
		});
  }
}

export default Login
