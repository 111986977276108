import { CCard, CCardBody, CCol, CRow } from "@coreui/react"
import React, { useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import LoaderSpinner from "src/components/LoaderSpinner"
import NotificationList from "src/components/NotificationList"
import { Pagination } from "src/components/Pagination"

const MySwal = withReactContent(Swal)
const pageRow = 10

const Notif = () => {
  const history = useHistory()
	const [loading, setLoading] = useState(true)
  const [data, setData] = useState({
    data: [],
    page: 1,
    lastPage: 1,
  })

  const getData = (pageData = 1) => {
    setLoading(true);

    var data_post = {
      page: pageData,
      rows: pageRow,
      order: [
        {
          field: "created_at",
          sort: "DESC"
        }
      ]
    }

    http.get(`notification`,
      { params: data_post }
    )
    .then((res) => {
      console.log('res notif', res.data)
      if(res.data.status) {
        setData((state) => ({
          ...state,
          data: res.data.data.data,
          page: res.data.data.page,
          lastPage: res.data.data.lastPage,
        }));
      }
      setLoading(false);
    })
    .catch((error) => {
      setLoading(false);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    });
  }

  const btnPagination = (e) => {
    getData(e.selected + 1)
  }

  useEffect(() => {
    getData()
  }, [])

  return(
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Notification</h4>
              <div className="small text-medium-emphasis">Notif Center</div>
            </CCol>
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={loading}/>
            {
              data.data.length ===0 && !loading ? (
                <div className="text-center">No notifications yet</div>
              ) : (
                <>
                  {
                    data.data.map((item, index) => {
                      return(
                        <NotificationList key={index} item={item}/>
                      )
                    })
                  }
                </>
              )
            }
          </div>
          {
            data.data.length > 0 &&
            <Pagination
              page={data.page}
              totalPage={data.lastPage}
              handleOnChange={(e) => btnPagination(e)}
            />
          }
        </CCardBody>
      </CCard>
    </>
  )
}

export default Notif