import React, { useEffect, useState } from 'react'

import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilPencil
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import Loader from 'src/components/Loader'

const MySwal = withReactContent(Swal)

const UserDetail = () => {
  const { user_id } = useParams()
  const { state } = useLocation()
  const { path } = state
  const history = useHistory()
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState({})

  const getData = () => {
    setLoading(true)

    http.get(`users/${user_id}`)
    .then((res) => {
      console.log('res', res.data)
      if(res.data.status){
        setData(res.data.data)
      }else{
        history.goBack()
        MySwal.fire({
          icon: 'error',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }

      setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      history.goBack()
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])

  return(
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                Detil
              </h4>
              <div className="small text-medium-emphasis">Content Data</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              <CButton
                color="info" 
                className="float-end text-white" 
                onClick={()=>history.push({
                  pathname: path+'/edit/'+user_id,
                  state: { type: 'edit', data: data, path: path }
                })}
              >
                <CIcon icon={cilPencil} />&nbsp; Edit User
              </CButton>
            </CCol>
          </CRow>
          <CRow className='mt-3'>
            <CCol>
              <dl className="row mb-0">
                <dt className="col-sm-4">Name</dt>
                <dd className="col-sm-8">{data.fullname}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Email</dt>
                <dd className="col-sm-8">{data.email}</dd>
              </dl>
              <dl className="row mb-0">
                <dt className="col-sm-4">Status</dt>
                <dd className="col-sm-8">
                  {
                    data.status==="1" ? (
                      <CBadge color="success">Active</CBadge>
                    ) : (
                      <CBadge color="danger">Inactive</CBadge>
                    )
                  }
                </dd>
              </dl>
              {
                data.phone &&
                <dl className="row mb-0">
                  <dt className="col-sm-4">Phone</dt>
                  <dd className="col-sm-8">{data.phone}</dd>
                </dl>
              }
              {
                data.address &&
                <dl className="row mb-0">
                  <dt className="col-sm-4">Address</dt>
                  <dd className="col-sm-8">{data.address}</dd>
                </dl>
              }
              {
                data.open &&
                <dl className="row mb-0">
                  <dt className="col-sm-4">Open At</dt>
                  <dd className="col-sm-8">{data.open}</dd>
                </dl>
              }
              {
                data.close &&
                <dl className="row mb-0">
                  <dt className="col-sm-4">Close At</dt>
                  <dd className="col-sm-8">{data.close}</dd>
                </dl>
              }
            </CCol>
            {
              data.parent &&
              <CCol>
                <dl className="row mb-0">
                  <dt className="col-sm-4">Admin Name</dt>
                  <dd className="col-sm-8">{data.parent.fullname}</dd>
                </dl>
                <dl className="row mb-0">
                  <dt className="col-sm-4">Admin Email</dt>
                  <dd className="col-sm-8">{data.parent.email}</dd>
                </dl>
                {
                  data.parent.phone &&
                  <dl className="row mb-0">
                    <dt className="col-sm-4">Admin Phone</dt>
                    <dd className="col-sm-8">{data.parent.phone}</dd>
                  </dl>
                }
                {
                  data.parent.address &&
                  <dl className="row mb-0">
                    <dt className="col-sm-4">Admin Address</dt>
                    <dd className="col-sm-8">{data.parent.address}</dd>
                  </dl>
                }
              </CCol>
            }
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default UserDetail