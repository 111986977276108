import { cilFeaturedPlaylist } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import React from "react"
import styled from 'styled-components'
import moment from "moment"
import http from "src/service/Request";
import { useHistory, useLocation } from "react-router-dom";

const NotifContentWrapper = styled.div`
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid #d7ecdd;
  min-width: 20rem;
`;

const NotifTitle = styled.div`
  color: #000;
  font-size: .8rem;
  font-weight: 600;
  margin-top: 5px;
`;

const Notiftext = styled.div`
  color: #000;
  font-size: .7rem;
`;

const AdsType = styled.div`
  color: #68726f;
  font-size: .7rem;
  margin-left: 5px
`

const NotificationList = (props) => {
  const { item } = props
  const history = useHistory()
  const location = useLocation()
  
  return(
    <NotifContentWrapper style={{backgroundColor: item.status===0?'#e8ffed':'#fff'}} onClick={()=>openNotif(item)}>
      <div className='d-flex align-items-center'>
        <CIcon icon={cilFeaturedPlaylist} size="sm"/>
        <AdsType>Ads</AdsType>
        <AdsType>•</AdsType>
        <AdsType>{moment(item.created_at).format('DD MMM YYYY HH:mm')}</AdsType>
      </div>
      <NotifTitle>{item.title}</NotifTitle>
      <Notiftext>{item.message}</Notiftext>
    </NotifContentWrapper>
  )

  function openNotif(item){
    http.put(`notification/open/${item.notification_id}`)
    .then((res) => {
      console.log('notif open', res)
    })
    if(location.pathname.indexOf('/ads/detail/') > -1){
      history.replace({
        pathname: `/${item.url}${item.uid}`
      })
      window.location.reload()
    }else{
      history.push({
        pathname: `/${item.url}${item.uid}`
      })
    }
  }
}

export default NotificationList