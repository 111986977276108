import React, { useEffect, useState } from 'react'

import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CForm,
  CFormCheck,
  CFormInput,
  CFormLabel,
  CFormTextarea,
  CRow,
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilSave
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import VideoInput from 'src/components/VideoInput'
import DatePickerRange from 'src/components/DatePickerRange'
import moment from 'moment'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'
import AdminSelect from 'src/components/AdminSelect'

const MySwal = withReactContent(Swal)

const AdsForm = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const history = useHistory()
  const { ads_id } = useParams()
  const { state } = useLocation()
  const { type, data, category_id } = state
	const [loading, setLoading] = useState(false)
  const [dataForm, setDataForm] = useState({
    name: '',
    description: '',
    url: null,
    date: { start: new Date(), end: new Date() }
  })
  const [dataFormAdmin, setDataFormAdmin] = useState({
    apply: 0,
    user: ''
  })

  console.log('daaform', dataForm)

  useEffect(()=>{
    if(type==='edit'){
      setDataForm(state => ({
        ...state,
        name: data.ads_name,
        description: data.ads_description,
        url: data.url,
        date: { start: moment(data.start_date).format('YYYY-MM-DD'), end: moment(data.end_date).format('YYYY-MM-DD') }
      }))
    }
  }, [])

  return (
    <>
      <Loader visible={loading}/>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {type==='add'?'Add':'Edit'} Ads
              </h4>
              <div className="small text-medium-emphasis">Video Data</div>
            </CCol>
          </CRow>
          <CRow className="mt-3">
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Ads Name</CFormLabel>
                  <CFormInput
                    type="input"
                    placeholder="Ads Name"
                    value={dataForm.name}
                    onChange={(txt)=>setDataForm(state => ({...state, name: txt.target.value}))}
                  />
                </div>
              </CForm>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Description</CFormLabel>
                  <CFormTextarea
                    type="input"
                    placeholder="Ads Description"
                    rows="7"
                    value={dataForm.description}
                    onChange={(txt)=>setDataForm(state => ({...state, description: txt.target.value}))}
                  />
                </div>
                <div className="mb-3">
                <CFormLabel>Schedule</CFormLabel>
                  <DatePickerRange date={dataForm.date} onChange={(range)=>setDataForm(state => ({ ...state, date: { start: range.startDate, end: range.endDate }}))}/>
                </div>
              </CForm>
            </CCol>
            <CCol md={6}>
              <CForm>
                <div className="mb-3">
                  <CFormLabel>Video</CFormLabel>
                  <VideoInput
                    disabled={loading}
                    value={dataForm.url}
                    onChange={(data)=>setDataForm(state => ({...state, url: data.url }))}
                  />
                </div>
              </CForm>
            </CCol>
            {/* <CCol md={6}>
              <div className="mb-3">
                <CFormLabel>Schedule</CFormLabel>
                <DatePickerRange date={dataForm.date} onChange={(range)=>setDataForm(state => ({ ...state, date: { start: range.startDate, end: range.endDate }}))}/>
              </div>
            </CCol> */}
          </CRow>
          {
            user.type==='admin' &&
            <CRow>
              <CCol md={6}>
                <div className="mb-3">
                  <CFormLabel>Apply For</CFormLabel>
                  <div className="d-flex flex-row mt-2">
                    <CFormCheck
                      type="radio"
                      label="All Partner"
                      checked={dataFormAdmin.apply===0}
                      onClick={()=>setDataFormAdmin(state => ({...state, apply: 0}))}
                    />
                    <CFormCheck
                      type="radio"
                      label="Partner"
                      className="ms-3"
                      checked={dataFormAdmin.apply===1}
                      onClick={()=>setDataFormAdmin(state => ({...state, apply: 1}))}
                    />
                  </div>
                </div>
              </CCol>
              {
                dataFormAdmin.apply===1 &&
                <CCol md={6}>
                  <CForm>
                    <div className="mb-3">
                      <CFormLabel>Partner</CFormLabel>
                      <AdminSelect
                        type="partner"
                        value={dataFormAdmin.data}
                        onSelect={(data)=>setDataFormAdmin(state => ({...state, user: data}))}
                      />
                    </div>
                  </CForm>
                </CCol>
              }
            </CRow>
          }
          <CButton 
            color="info" 
            className="text-white"
            onClick={()=>type==='add'?create():edit()}
          >
            <CIcon icon={cilSave} /> &nbsp; Submit
          </CButton>
        </CCardBody>
      </CCard>
    </>
  )

  function create() {
    setLoading(true);
    var data_post = {
      ads_name: dataForm.name,
      ads_description: dataForm.description,
      ads_url: dataForm.url,
      start_date: moment(dataForm.date.start).format('YYYY-MM-DD'),
      end_date: moment(dataForm.date.end).format('YYYY-MM-DD'),
      category_id: category_id
    }

    if(dataFormAdmin.apply===1){
      data_post['partner'] = dataFormAdmin.user.user_id
    }

    http.post('ads', data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }

  function edit() {
    setLoading(true);
    const data_post = {
      ads_name: dataForm.name,
      ads_description: dataForm.description,
      ads_url: dataForm.url,
      start_date: moment(dataForm.date.start).format('YYYY-MM-DD'),
      end_date: moment(dataForm.date.end).format('YYYY-MM-DD'),
      category_id: category_id
    }
    http.put('ads/'+ads_id, data_post)
    .then(res => {
      console.log('res', res);
      if(res.data.status){
        history.goBack();
        MySwal.fire({
          icon: 'success',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }else{
        MySwal.fire({
          icon: 'warning',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
    .catch(error => {
      console.log('error', error.response);
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else if (error.request) {
        MySwal.fire({
          icon: 'error',
          text: 'Please check your internet connection',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Terjadi Kesalahan',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false);
    })
  }
}

export default AdsForm
