import React, { useEffect, useState } from 'react'

import {
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CRow,
  CWidgetStatsA
} from '@coreui/react'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import Loader from 'src/components/Loader';
import CIcon from '@coreui/icons-react';
import { cilGroup } from '@coreui/icons';
import Cookies from 'js-cookie';
import { decrypt } from 'src/service/Cryptograph';

const MySwal = withReactContent(Swal)

const Dashboard = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const [loading, setLoading] = useState(true)
  const [summary, setSummary] = useState([])

  console.log('summary', summary)

  const getData = () => {
    setLoading(true)

    http.get(`dashboard`)
    .then((res) => {
      console.log('res', res)
      if(res.data.status){
        setSummary(res.data.data)
      }else{
        MySwal.fire({
          icon: 'error',
          text: res.data.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
      setLoading(false)
    })
    .catch((error) => {
      console.log('error', error)
      setLoading(false)
      if (error.response) {
        MySwal.fire({
          icon: 'error',
          text: error.response.data.error.message,
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      } else {
        MySwal.fire({
          icon: 'error',
          text: 'Oops.. Something Wrong!',
          confirmButtonColor: BrandColor(),
          confirmButtonText: 'Close'
        })
      }
    })
  }

  useEffect(() => {
    getData()
  }, [])
  
  return (
    <>
    {
      user.type!=='partner' &&
      <CRow>
          {
            user.type==='superadmin' &&
            <CCol>
              <CWidgetStatsA
                className="mb-4"
                color="info"
                value={
                  <div className='d-flex align-items-center mb-3'>
                    <div style={{width:60}}>
                      <CIcon icon={cilGroup} customClassName="nav-icon" color='#fff' />
                    </div>
                    <div className='flex-grow-1 ms-4'>
                      <div>
                        {summary.admin}
                      </div>
                      <span className="fs-6 fw-normal">
                        Admin
                      </span>
                    </div>
                  </div>
                }
              />
            </CCol>
          }
          <CCol>
            <CWidgetStatsA
              className="mb-4"
              color="info"
              value={
                <div className='d-flex align-items-center mb-3'>
                  <div style={{width:60}}>
                    <CIcon icon={cilGroup} customClassName="nav-icon" color='#fff' />
                  </div>
                  <div className='flex-grow-1 ms-4'>
                    <div>
                      {summary.partner}
                    </div>
                    <span className="fs-6 fw-normal">
                      Partner
                    </span>
                  </div>
                </div>
              }
            />
          </CCol>
        </CRow>
      }
      <CCard className="mb-4">
        <CCardHeader>Summary Data Ads</CCardHeader>
        <Loader visible={loading}/>
        <CCardBody>
          <CRow>
            <CCol sm={6} md={3}>
              <div className="border-start border-start-4 border-start-success py-1 px-3 mb-3">
                <div className="text-medium-emphasis small">Active</div>
                <div className="fs-5 fw-semibold">{summary?.ads?.active}</div>
              </div>
            </CCol>
            <CCol sm={6} md={3}>
              <div className="border-start border-start-4 border-start-info py-1 px-3 mb-3">
                <div className="text-medium-emphasis small">Upcoming</div>
                <div className="fs-5 fw-semibold">{summary?.ads?.upcoming}</div>
              </div>
            </CCol>
            <CCol sm={6} md={3}>
              <div className="border-start border-start-4 border-start-danger py-1 px-3 mb-3">
                <div className="text-medium-emphasis small">Expired</div>
                <div className="fs-5 fw-semibold">{summary?.ads?.expired}</div>
              </div>
            </CCol>
            <CCol sm={6} md={3}>
              <div className="border-start border-start-4 border-start-waring py-1 px-3 mb-3">
                <div className="text-medium-emphasis small">Play</div>
                <div className="fs-5 fw-semibold">{summary?.ads?.play}</div>
              </div>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Dashboard
