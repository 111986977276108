import React, { useEffect, useState } from 'react'

import {
  CBadge,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CRow,
  CTable,
  CTableBody,
  CTableDataCell,
  CTableHead,
  CTableHeaderCell,
  CTableRow
} from '@coreui/react'
import CIcon from '@coreui/icons-react'
import {
  cilNotes,
  cilPlus
} from '@coreui/icons'
import http from 'src/service/Request'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content';
import { BrandColor } from 'src/components/Color'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import LoaderSpinner from 'src/components/LoaderSpinner'
import { Pagination } from 'src/components/Pagination'

const MySwal = withReactContent(Swal)
const pageRow = 15

const VideoByCategory = (props) => {
  const history = useHistory()
  const { user_id, type } = props
  const [title, setTitle] = useState('')
  const [ads, setAds] = useState({
    loading: true,
    data: [],
    page: 1,
    lastPage: 1,
    total: 0
  })

  useEffect(()=>{
    setTitle(type)
  }, [type])

  const getAds = (pageData = 1) => {
    setAds((state) => ({
      ...state,
      loading: true
    }))

    var data_post = {
      page: pageData,
      rows: pageRow
    };

    http.get(`ads/monitoring/${user_id}/${type}`,
      { params: data_post }
    )
      .then((res) => {
        console.log('res', res.data)
        if(res.data.status) {
          setAds((state) => ({
            ...state,
            data: res.data.data.data,
            page: res.data.data.page,
            lastPage: res.data.data.lastPage,
            total: res.data.data.total
          }))
        }
        setAds((state) => ({
          ...state,
          loading: false
        }))
      })
      .catch((error) => {
        setAds((state) => ({
          ...state,
          loading: false
        }))

        if (error.response) {
          MySwal.fire({
            icon: 'error',
            text: error.response.data.error.message,
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        } else {
          MySwal.fire({
            icon: 'error',
            text: 'Oops.. Something Wrong!',
            confirmButtonColor: BrandColor(),
            confirmButtonText: 'Close'
          })
        }
      });
  };

  const btnPagination = (e) => {
    getAds(e.selected + 1)
  }

  useEffect(() => {
    if(user_id){
      getAds()
    }
  }, [user_id])

  return (
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol sm={5}>
              <h4 id="traffic" className="card-title mb-0">
                {title.charAt(0).toUpperCase() + title.slice(1)}
              </h4>
              <div className="small text-medium-emphasis">Total: {ads.data.length}</div>
            </CCol>
            <CCol sm={7} className="d-none d-md-block">
              {
                props.addButton &&
                <CButton 
                  color="info" 
                  className="float-end text-white" 
                  onClick={()=>history.push({
                    pathname: `/ads-monitoring/add`,
                    state: { type: 'add' }
                  })}
                >
                  <CIcon icon={cilPlus} />&nbsp; Request Video
                </CButton>
              }
            </CCol>
          </CRow>
          <div className="relative mt-3">
            <LoaderSpinner visible={ads.loading}/>
            <CTable align="middle" className="mb-0 border" hover responsive>
              <CTableHead color="light">
                <CTableRow>
                  <CTableHeaderCell>Ads Name</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Start Date</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">End Date</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Play</CTableHeaderCell>
                  <CTableHeaderCell className="text-center">Action</CTableHeaderCell>
                </CTableRow>
              </CTableHead>
              {
                ads.data.length ===0 && !ads.loading ? (
                  <CTableBody>
                    <CTableRow>
                      <CTableDataCell colSpan="6" className="text-center">Data not found</CTableDataCell>
                    </CTableRow>
                </CTableBody>
                ) : (
                  <CTableBody>
                    {
                      ads.data.map((item, index) => {
                        return(
                          <CTableRow key={index}>
                            <CTableDataCell>{item.ads_name}{item?.user_from?.type==='admin'?<CBadge color='info' className='ms-2'>Admin</CBadge>:null}</CTableDataCell>
                            <CTableDataCell className="text-center">{moment(item.start_date).format('DD MMM YYYY')}</CTableDataCell>
                            <CTableDataCell className="text-center">{moment(item.end_date).format('DD MMM YYYY')}</CTableDataCell>
                            <CTableDataCell className="text-center">{item.display}</CTableDataCell>
                            <CTableDataCell className="text-center">
                              <CButton 
                                color="success" 
                                className="text-white" 
                                onClick={()=>history.push({
                                  pathname: '/ads-monitoring/detail/'+item.ads_id
                                })}
                                size="sm"
                              >
                                <CIcon icon={cilNotes} />&nbsp; Detail
                              </CButton>
                            </CTableDataCell>
                          </CTableRow>
                        )
                      })
                    }
                  </CTableBody>
                )
              }
            </CTable>
            {
              ads.data.length > 0 &&
              <Pagination
                page={ads.page}
                totalPage={ads.lastPage}
                handleOnChange={(e) => btnPagination(e)}
              />
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default VideoByCategory