import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { CImage, CSidebar, CSidebarBrand, CSidebarNav, CSidebarToggler } from '@coreui/react'

import { AppSidebarNav } from './AppSidebarNav'

import SimpleBar from 'simplebar-react'
import 'simplebar/dist/simplebar.min.css'

// sidebar nav config
import { nav_su, nav_admin, nav_partner } from '../_nav'

import logoNegative from './../assets/brand/logo-light.png'
import sygnet from './../assets/brand/logo-initial-light.png'
import { decrypt } from 'src/service/Cryptograph'
import Cookies from 'js-cookie'

const AppSidebar = () => {
  const dispatch = useDispatch()
  const unfoldable = useSelector((state) => state.sidebarUnfoldable)
  const sidebarShow = useSelector((state) => state.sidebarShow)
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const [sidebarNav, setSidebarNav] = useState(null)

  console.log('user', user)
  
  useEffect(()=>{
    if(user){
      if(user.type==='superadmin'){
        setSidebarNav(nav_su)
      }else if(user.type==='admin'){
        setSidebarNav(nav_admin)
      }else if(user.type==='partner'){
        setSidebarNav(nav_partner)
      }
    }
  }, [user])

  return (
    <CSidebar
      position="fixed"
      unfoldable={unfoldable}
      visible={sidebarShow}
      onVisibleChange={(visible) => {
        dispatch({ type: 'set', sidebarShow: visible })
      }}
    >
      <CSidebarBrand className="d-none d-md-flex" to="/">
        <CImage className="sidebar-brand-full" src={logoNegative} height={30} />
        <CImage className="sidebar-brand-narrow" src={sygnet} height={25} />
      </CSidebarBrand>
      <CSidebarNav>
        <SimpleBar>
          <AppSidebarNav items={sidebarNav} />
        </SimpleBar>
      </CSidebarNav>
      <CSidebarToggler
        className="d-none d-lg-flex"
        onClick={() => dispatch({ type: 'set', sidebarUnfoldable: !unfoldable })}
      />
    </CSidebar>
  )
}

export default React.memo(AppSidebar)
