import { CModal, CModalBody, CModalHeader, CModalTitle } from "@coreui/react"
import React, { useEffect, useState } from "react"
import UserList from "./UserList"
import styled from 'styled-components';

const SelectBox = styled.div`
  cursor: pointer
`;

const Placeholder = styled.div`
  color: #8b93a2
`;

const AdminSelect = (props) => {
  const [modal, setModal] = useState(false)
  const [value, setValue] = useState('')

  useEffect(()=>{
    setValue(props.value?props.value:'')
  }, [props.value])

  return(
    <div>
      <SelectBox onClick={()=>setModal(true)}>
        <div className="form-select">
          {
            value===''?(
              <Placeholder>
                Select User
              </Placeholder>
            ):(
              <div>{value.fullname} ({value.email})</div>
            )
          }
        </div>
      </SelectBox>

      <CModal
        alignment="center"
        size="lg"
        visible={modal}
        onClose={() => setModal(false) }
      >
        <CModalHeader>
          <CModalTitle>Select User</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <UserList
            path={'/'+props.type}
            value={value===''?{}:value}
            onSelect={(data)=>{
              setValue(data)
              setModal(false)
              props.onSelect(data)
            }}
          />
        </CModalBody>
      </CModal>
    </div>
  )
}

export default AdminSelect