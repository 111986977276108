import axios from "axios";
import { decrypt } from "./Cryptograph";
import Cookies from "js-cookie"

const baseURL = process.env.REACT_APP_API;

const http = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json'
  },
});

http.interceptors.request.use(
  async config => {
    let token = Cookies.get("token") ? decrypt(Cookies.get("token")) : "";
    // return config;
    config.headers["Authorization"] = token;
    return config;
  },
  error => {
    return Promise.reject(error)
  }

)


export default http
