import React, { useEffect, useState } from 'react'
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangePicker } from 'react-date-range';
import { CButton, CFormInput, CInputGroup, CInputGroupText, CModal, CModalBody, CModalFooter, CModalHeader, CModalTitle } from '@coreui/react';
import moment from 'moment';
import CIcon from '@coreui/icons-react';
import { cilCalendar } from '@coreui/icons';

const DatePickerRange = (props) => {
  const [state, setState] = useState([
    {
      startDate: props.date.start,
      endDate: props.date.end,
      key: 'selection'
    }
  ]);
  const [visible, setVisible] = useState(false)
  const [date, setDate] = useState(state);

  useEffect(()=>{
    const dataDate = [
      {
        startDate: moment(props.date.start).toDate(),
      
        endDate: moment(props.date.end).toDate(),
        key: 'selection'
      }
    ]
    setState(dataDate)

    setDate(dataDate)
  }, [props.date])

  return(
    <div>
      <div onClick={()=>setVisible(true)} className='date-picker-custom-range'>
        <CInputGroup>
          <CFormInput
            type="input"
            placeholder="Ads Name"
            disabled
            value={moment(date[0].startDate).format('DD MMM YYYY') +' - '+ moment(date[0].endDate).format('DD MMM YYYY')}
            style={{background:'#fff'}}
          />
          <CInputGroupText>
            <CIcon icon={cilCalendar} />
          </CInputGroupText>
        </CInputGroup>
      </div>
      <CModal size="xl" visible={visible} onClose={() => setVisible(false)}>
        <CModalHeader 
          onClose={() => {
            setVisible(false)
            setState(date);
          }}
        >
          <CModalTitle>Select Date</CModalTitle>
        </CModalHeader>
        <CModalBody>
          <DateRangePicker
            onChange={(item) => setState([item.selection])}
            showSelectionPreview={true}
            moveRangeOnFirstSelection={false}
            calendarFocus="backwards"
            months={2}
            ranges={state}
            direction="horizontal"
            minDate={new Date()}
          />
        </CModalBody>
        <CModalFooter>
          <CButton 
            color="secondary" 
            onClick={() => {
              setVisible(false)
              setState(date)
            }}
            className='text-white'
          >
            Close
          </CButton>
          <CButton 
            color="info"
            className="text-white"
            onClick={() => {
              setVisible(false)
              setDate(state)
              props.onChange(state[0])
            }}
          >Save changes</CButton>
        </CModalFooter>
      </CModal>
    </div>
  )
}

export default DatePickerRange