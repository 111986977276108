import { CCard, CCardBody, CCol, CFormLabel, CRow } from '@coreui/react'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import AdminSelect from 'src/components/AdminSelect'
import VideoByCategory from 'src/components/VideoByCategory'
import { decrypt } from 'src/service/Cryptograph'

const Monitoring = () => {
  var user = JSON.parse(decrypt(Cookies.get('user')))
  const [partner, setPartner] = useState('')

  useEffect(()=>{
    if(user.type==='partner'){
      setPartner(user)
    }
  }, [])

  return(
    <>
      <CCard className="mb-4">
        <CCardBody>
          <CRow>
            <CCol md={8}>
              <h4 id="traffic" className="card-title mb-0">Ads Monitoring</h4>
              <div className="small text-medium-emphasis">Monitoring</div>
            </CCol>
          </CRow>
          <div className="relative mt-3">
            {
              user.type!=='partner' &&
              <div className='mb-4'>
                <CFormLabel>Select Partner</CFormLabel>
                <AdminSelect
                  type="partner"
                  value={partner}
                  onSelect={(data)=>setPartner(data)}
                />
              </div>
            }

            {
              partner===''?(
                <div className="alert alert-warning" role="alert">
                  Please select partner first
                </div>
              ):(
                <div>
                  <VideoByCategory
                    user_id={partner.user_id} 
                    type={'active'}
                  />

                  <VideoByCategory
                    user_id={partner.user_id} 
                    type={'upcoming'}
                  />

                  <VideoByCategory
                    user_id={partner.user_id} 
                    type={'expired'}
                  />
                </div>
              )
            }
          </div>
        </CCardBody>
      </CCard>
    </>
  )
}

export default Monitoring